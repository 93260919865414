import i18n from "@/libs/i18n";

const DiscountTypes = [
    {
        id: '%',
        name: i18n.t('discounts.discounts.%'),
        label: i18n.t('discounts.discounts.%'),
    },
    {
        id: 'v',
        name: i18n.t('discounts.discounts.v'),
        label: i18n.t('discounts.discounts.v'),
    },

];

function filterDiscountType(id) {
    return DiscountTypes.find((item) => item.id === id);
}

export {DiscountTypes, filterDiscountType};
