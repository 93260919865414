import i18n from "@/libs/i18n";

const DiscountConditions = [
    {
        id: '0',
        label: i18n.t('discounts.condition.without'),
        type: 'none',
        conditions: {
            title: "",
            children: []
        }
    },
    {
        id: 'location',
        label: i18n.t('discounts.condition.location'),
        type: 'discount',
        conditions: {
            title: "",
            children: [
                {
                    id: ">",
                    label: i18n.t('discounts.condition.greater_than'),
                },
                {
                    id: "<",
                    label: i18n.t('discounts.condition.less_than'),
                },
            ]
        }
    },
    {
        id: 'order_period',
        type: 'discount',
        label: i18n.t('discounts.condition.order'),
        conditions: {
            title: "",
            children: [
                {
                    id: 1,
                    label: i18n.t('discounts.period.1'),
                },
                {
                    id: 7,
                    label: i18n.t('discounts.period.7'),
                },
                {
                    id: 14,
                    label: i18n.t('discounts.period.14'),
                },
                {
                    id: 30,
                    label: i18n.t('discounts.period.30'),
                },
            ]
        }
    },
    {
        id: 'delivery_type',
        type: 'usage',
        label: i18n.t('discounts.condition.delivery_type'),
        conditions: {
            title: "",
            children: []
        }
    },
    {
        id: 'payment_type',
        type: 'usage',
        label: i18n.t('discounts.condition.payment_type'),
        conditions: {
            title: "",
            children: []
        }
    },
    {
        id: 'new_user',
        type: 'usage',
        label: i18n.t('discounts.condition.new_user'),
        conditions: {
            title: "",
            children: []
        }
    },
    {
        id: 'select_user',
        type: 'usage',
        label: i18n.t('discounts.condition.select_user'),
        conditions: {
            title: "",
            children: []
        }
    },
    {
        id: 'order_count',
        type: 'usage',
        label: i18n.t('discounts.condition.order'),
        conditions: {
            title: "",
            children: []
        }
    },

];

function filterDiscountCondition(id) {
    return DiscountConditions.find((item) => item.id === id);
}

function filterDiscountConditionTypes(type) {
    return DiscountConditions.filter((item) => item.type === type || item.type === 'none');
}

export {DiscountConditions, filterDiscountCondition,filterDiscountConditionTypes};
